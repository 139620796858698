var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"cast-point-history"}},[_c('card-header',{ref:"header",attrs:{"node":_vm.header}}),_c('div',{staticClass:"content-scroll",attrs:{"id":"content-scroll"}},[(_vm.user_pg_points.user_pg_points)?_c('div',{staticClass:"content d-flex flex-column align-items-center"},[_c('div',{staticClass:"content__points-label f-w3"},[_vm._v("ポイント残高")]),_c('div',{staticClass:"content__points-value f-w6"},[_vm._v(" "+_vm._s(parseInt(_vm.user_pg_points.deposit_points) > 0 ? Intl.NumberFormat() .format(_vm.user_pg_points.deposit_points) .replace(/\./g, ",") : 0)+"P ")]),_vm._m(0),(_vm.user.user_type === 2)?_c('div',{staticClass:"content__Withdrawal f-w6",on:{"click":_vm.checkIsBankAcc}},[_c('img',{attrs:{"src":require("@/assets/image/icon-refresh.svg"),"alt":""}}),_vm._v(" 出金申請 ")]):_vm._e(),_c('div',{staticClass:"content__history"},[_c('div',{staticClass:"content__history__tab d-flex"},[_c('div',{staticClass:"f-w6",class:{ actived: _vm.type == 1 },on:{"click":function($event){return _vm.nextTab(1)}}},[_vm._v(" 獲得 ")]),_c('div',{staticClass:"f-w6",class:{ actived: _vm.type == 2 },on:{"click":function($event){return _vm.nextTab(2)}}},[_vm._v(" 支払い ")]),(_vm.user.user_type === 2)?_c('div',{staticClass:"f-w6",class:{ actived: _vm.type == 3 },on:{"click":function($event){return _vm.nextTab(3)}}},[_vm._v(" 申請 ")]):_vm._e()]),(_vm.user_pg_points.user_pg_points.total > 0)?_c('div',{staticClass:"content__history__wrap",class:[
            !_vm.user.bank_name && _vm.user.user_type === 2
              ? 'content__history__wrap--bank'
              : '',
            _vm.user.user_type == 1 ? 'content__history__wrap--regular' : ''
          ]},[_vm._l((_vm.user_pg_points.user_pg_points
              .user_pg_points),function(item,index){return _c('div',{key:index,staticClass:"content__history__wrap__item"},[_c('div',{staticClass:"d-flex justify-content-between align-items-center"},[_c('div',{staticClass:"content__history__wrap__item__time f-w3"},[_vm._v(" "+_vm._s(_vm.$dayjs(item.transacted_at).format("YYYY/MM/DD HH:mm"))+" ")])]),_c('div',{staticClass:"\n                content__history__wrap__item__reason\n                f-w3\n                text-left\n                d-flex\n                justify-content-between\n              "},[_vm._v(" "+_vm._s(item.reason)+" "),_c('div',{staticClass:"f-w6",class:[
                  parseInt(item.points) >= 0
                    ? 'content__history__wrap__item__points'
                    : 'content__history__wrap__item__point-change--minus'
                ]},[_vm._v(" "+_vm._s(parseInt(item.points) > 0 ? "+" + Intl.NumberFormat() .format(item.points) .replace(/\./g, ",") : Intl.NumberFormat() .format(item.points) .replace(/\./g, ","))+"P ")])]),(
                item.deposit_reason_id === 1 || item.deposit_reason_id === 2
              )?_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.detail-receiption",modifiers:{"detail-receiption":true}}],staticClass:"content__history__wrap__item__receipt f-w3",on:{"click":function($event){return _vm.showSettingPdf(item)}}},[_vm._v(" 領収書 ")]):_vm._e(),(
                item.withdrawal_reason_id === 1 ||
                  item.withdrawal_reason_id === 2
              )?_c('button',{staticClass:"content__history__wrap__item__details f-w3",on:{"click":function($event){return _vm.detailPoint(item)}}},[_vm._v(" 合流ポイントの詳細を表示 ")]):_vm._e()])}),(_vm.user_pg_points.user_pg_points.total > 12)?_c('b-pagination',{staticClass:"pagination f-w6 w-100",attrs:{"total-rows":_vm.user_pg_points.user_pg_points.total,"per-page":_vm.perPage,"first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}},[(_vm.currentPage == 1)?_c('template',{slot:"prev-text"},[_c('img',{attrs:{"src":require("@/assets/image/left-arrow-pagination.svg"),"alt":""}})]):_vm._e(),(_vm.currentPage != 1)?_c('template',{slot:"prev-text"},[_c('img',{staticClass:"left-active-pagination",attrs:{"src":require("@/assets/image/right-arrow-pagination.svg"),"alt":""}})]):_vm._e(),(
                _vm.currentPage < _vm.user_pg_points.user_pg_points.total / _vm.perPage
              )?_c('template',{slot:"next-text"},[_c('img',{attrs:{"src":require("@/assets/image/right-arrow-pagination.svg"),"alt":""}})]):_vm._e(),(
                _vm.currentPage >= _vm.user_pg_points.user_pg_points.total / _vm.perPage
              )?_c('template',{slot:"next-text"},[_c('img',{staticClass:"right-active-pagination",attrs:{"src":require("@/assets/image/left-arrow-pagination.svg"),"alt":""}})]):_vm._e()],2):_vm._e(),_c('ModalLayout',{ref:"settingPdf",attrs:{"defaultStyle":false}},[_c('detail-receiption',{attrs:{"item":_vm.itemShowPdf},on:{"downloadPdf":function($event){return _vm.downloadPdf($event)}}})],1),_c('ModalLayout',{ref:"downloadPdf",attrs:{"defaultStyle":false}},[_c('pdf-download',{attrs:{"data":_vm.dataExportPdf},on:{"cancelDownloadPDF":function($event){return _vm.cancelDownloadPDF()}}})],1)],2):_vm._e(),(_vm.user_pg_points.user_pg_points.total === 0)?_c('div',{staticClass:"empty"},[_c('img',{attrs:{"src":require("@/assets/image/empty_data.svg"),"alt":""}}),_c('p',{staticClass:"f-w3"},[_vm._v("獲得ポイントはありません")])]):_vm._e()]),(!_vm.user.bank_name && _vm.user.user_type === 2)?_c('div',{staticClass:"\n          content__registration__wrap\n          d-flex\n          justify-content-center\n          align-items-center\n          f-w6\n        ",on:{"click":_vm.nextBankAccountRegistration}},[_vm._m(1)]):_vm._e()]):_vm._e()]),_c('card-footer',{ref:"footer"}),_c('ModalLayout',{ref:"withdraw",attrs:{"defaultStyle":false}},[_c('PointHistoryWithdraw',{attrs:{"totalPoint":_vm.user_pg_points.deposit_points}})],1),_c('ModalLayout',{ref:"bankAcc",attrs:{"defaultStyle":false}},[_c('BankAccRegister')],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content__points-des f-w3"},[_c('p',[_vm._v("ポイントは最低3,000Pからオートチャージされます。")]),_c('p',[_vm._v("ポイントの有効期限は150日となります。ご了承ください。")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content__registration"},[_c('span',[_vm._v("銀行口座登録")])])}]

export { render, staticRenderFns }